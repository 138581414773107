/* TOBOHeader */

#toboPage header {
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;
}

#toboPage header h1 {
  cursor: pointer;
  width: max-content;
  margin: auto;
}

img#toboLogo {
  height: 80px;
  margin: auto;
  display: block;
  padding: 20px 40px;
  background-color: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

img#toboMobileLogo {
  display: none;
}

#toboPage header button {
  background-color: #000;
  color: #fff;
  font-family: "inter-bold", sans-serif;
  font-size: 22px;
  padding: 31px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 14px;
  position: absolute;
  right: 15px;
  top: 14px;
  cursor: pointer;
  box-shadow: none;
  text-transform: capitalize;
}

#toboPage header .hide {
  display: none;
}

@media only screen and (max-width: 767px) {
  #toboPage header {
    height: 60px;
  }

  #toboPage header button {
    display: none;
  }

  img#toboLogo {
    display: none;
  }

  img#toboMobileLogo {
    display: inline;
    height: 60px;
    padding: 4px;
  }

  #toboPage header button {
    font-size: 18px;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 14px;
    right: 10px;
    top: 11px;
  }
}
